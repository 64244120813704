<template>
  <div id="kt_header" class="header align-items-stretch" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
    <div class="container-xxl d-flex align-items-center">
      <KTTopbar />
      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <KTMenu />
        <div class="topbar d-flex align-items-stretch flex-shrink-0">
          <div class="d-flex align-items-center" id="kt_header_user_menu_toggle">
              <div class="btn btn-icon btn-active-light-primary btn btn-icon btn-active-light-primary btn-custom w-50px h-50px w-md-40px h-md-40px" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                  <img alt="Avatar" class="h-50px w-50px rounded-circle" :src="'https://ui-avatars.com/api/?name='+user.nama_user+'&bold=true'" style="object-fit: cover;"/>
              </div>
              <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
                  <div class="menu-item px-3">
                      <div class="menu-content d-flex align-items-center px-3">
                          <div class="symbol symbol-50px me-5">
                              <img alt="Avatar" :src="'https://ui-avatars.com/api/?name='+user.nama_user+'&bold=true'" />
                          </div>
                          <div class="d-flex flex-column">
                              <div class="fw-bolder d-flex align-items-center fs-5">
                                <span>{{ user.nama_user }}</span>
                                <span class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{{ user.jabatan }}</span>
                              </div>
                              <a href="#" class="fw-bold text-muted text-hover-primary fs-7">{{ user.email }}</a>
                          </div>
                      </div>
                  </div>
                  <div class="separator my-2"></div>
                  <div class="menu-item px-5">
                      <a href="#" class="menu-link px-5">Profile</a>
                  </div>
                  <div class="menu-item px-5 my-1">
                      <a href="#" class="menu-link px-5">Settings</a>
                  </div>
                  <div class="menu-item px-5">
                      <a @click="logout()" class="menu-link px-5">Logout</a>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, watch, watchEffect, computed } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar, KTMenu
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const logout = () => {
      console.log('logout')
      store.dispatch(Actions.LOGOUT);

      Swal.fire({
        text: "You have successfully loged out!",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
        timer: 2000,
      }).then(function () {
        // Go to login page
        router.push('/auth/login')
      });

    }

    const user = computed(() => {
      return store.getters.currentUser
    })

    return {
      logout, router, user
    };
  },
});
</script>
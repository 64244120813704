<template>  
    <div class="d-flex align-items-stretch" id="kt_header_nav">
        <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" 
            data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" 
            data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" 
            data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true" data-kt-swapper-mode="prepend" 
            data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
        >
            <div
              class="menu menu-lg-rounded menu-column
                menu-lg-row menu-state-bg menu-title-gray-700
                menu-state-icon-primary menu-state-bullet-primary
                menu-arrow-gray-400 fw-bold my-5 my-lg-0
                align-items-stretch menu-sub-indention px-3 my-5"
              id="#kt_header_menu" data-kt-menu="true"
            >
                <div v-for="(parent, i) in menu" :key="i" :class="{'active': route.path.includes(parent.route) }" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" class="menu-item menu-lg-down-accordion me-lg-1">
                    <router-link v-if="parent.children.length == 0" :class="{'active': route.path == parent.route }" class="menu-link py-3" :to="'/'+parent.route">
                        <span class="menu-title">{{ parent.title }}</span>
                        <span class="menu-arrow" :class="{'d-none': parent.children.length == 0}"></span>
                    </router-link>
                    <span v-else class="menu-link py-3" :class="{'active': route.path.includes(parent.route) }">
                        <span class="menu-title">{{ parent.title }}</span>
                        <span class="menu-arrow" :class="{'d-none': parent.children.length == 0}"></span>
                    </span>
                    <div v-if="parent.children.length > 0" class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
                      <div v-for="(child, j) in parent.children" :key="j">
                          <div class="menu-item">
                              <router-link class="menu-link py-3" :class="{'active': route.path == child.route }" :to="'/'+child.route">
                                  <span class="menu-bullet">
                                      <span class="bullet bullet-dot"></span>
                                  </span>
                                  <span class="menu-title">{{ child.title }}</span>
                              </router-link>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onBeforeMount, onMounted, watchEffect, reactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const store = useStore()
    const route = useRoute();
    const router = useRouter();

    const menu = computed(() => {
        return store.getters['getMenu']
    })

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const getData = () => {
        try {
            store.dispatch(Actions.MENU);

        } catch(e) {
            console.error(e)
        }
    }
    
    onMounted(() => {
        getData()
    })

    return {
        route, router,
        menu,
        hasActiveChildren,
    };
  },
});
</script>

<template>
    <div class="btn btn-icon btn-color-gray-900 btn-active-color-primary w-35px h-35px me-5 d-lg-none" id="kt_header_menu_mobile_toggle">
        <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
        <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor"></path>
                <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="currentColor"></path>
            </svg>
        </span>
        <!--end::Svg Icon-->
    </div>
    <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
        <a href="">
            <img alt="Logo" src="/media/logos/logo-hitam-putih.png" class="logo-default h-25px" />
            <img alt="Logo" src="/media/logos/logo.png" class="logo-sticky h-25px" />
        </a>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "KTTopbar",
  components: {},
  setup() {
    const route = useRoute();
    //const data = stateMenu()

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
        //data,
        hasActiveChildren,
    };
  },
});
</script>
